import React, { useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useNotify,
} from 'react-admin';
import { CustomToolbar } from '../../components/Toolbar';

const optionRenderer = (choice) => {
  return `${choice.name}`;
};

const validatePlan = (values) => {
  const errors = {};

  if (!values.plan_name) {
    errors.plan_name = 'This field is required';
  }

  if (!values.duration) {
    errors.duration = 'This field is required';
  }

  return errors;
};

export const CreatePlan = (props) => {
  const [error, setError] = useState(null);
  const notify = useNotify();

  const onError = (error) => {
    console.log('Error response data:', error.response);
    notify(`subscription with this plan name already exists`);
  };

  return (
    <Create redirect="list" mutationOptions={{ onError }}>
      <SimpleForm
        toolbar={<CustomToolbar cancelPath="dashboard/subscription" />}
        validate={validatePlan}
      >
        <TextInput label="Plan Name" source="plan_name" />
        <TextInput source="duration" label="Trial Duration (in Days)" />

        <ReferenceInput source="product" reference="payments/product" fullWidth>
          <AutocompleteInput optionText={optionRenderer} />
        </ReferenceInput>
        <ReferenceArrayInput source="features" reference="features" fullWidth>
          <AutocompleteArrayInput optionText={optionRenderer} />
        </ReferenceArrayInput>
        <BooleanInput source="is_active" />
      </SimpleForm>
    </Create>
  );
};
