import React from 'react';
import {
  TextField,
  Tab,
  Button,
  useDataProvider,
  useRefresh,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { useMutation } from 'react-query';

export const SaveCardButton = () => {
  const record = useRecordContext();
  let { id } = record;
  console.log('SaveCardButton', record, id);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const { mutate, isLoading } = useMutation(
    ['save_card_for_now', record ? record.id : null],
    () => {
      const data = dataProvider.save_card_for_now(record ? record.id : null);
      console.log('SaveCardButton', data);
      data.then((data) => {
        console.log('SaveCardButton', data);
        window.location.href = data.redirect_url;
        if (data && data.message) {
          notify(data.message);
        }
        refresh();
      });
      return data;
    },
  );

  return (
    <Button
      variant="contained"
      label="Save Card for Later"
      onClick={() => {
        mutate();
        refresh();
      }}
      disabled={isLoading}
    />
  );
};
