import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

export default function useLogin() {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    if (
      username !== null &&
      username.trim() !== '' &&
      password !== null &&
      password.trim() !== ''
    ) {
      // Your logic for valid username and password
    } else {
      toast.warn('pls enter valid cred');
    }

    const loginData = {
      email: username,
      password: password,
    };

    axios
      .post('/api/tenant-auth/login/', loginData)
      .then((res) => {
        console.log(res);
        localStorage.setItem('LoginData', JSON.stringify(loginData));
        setIsProcessing(false);
        console.log('login_response', res.data.redirect_url);
        window.location.href = res.data.redirect_url;
        // You can add navigation logic here if needed
      })
      .catch((err, res) => {
        console.log('Error', err);
        Object.entries(err.response.data).forEach(([key, value]) => {
          toast.warn(`${value}`, 'warning');
        });
        setIsProcessing(false);
      });
  };

  return {
    handleSubmit,
    username,
    setUsername,
    usernameError,
    password,
    setPassword,
    passwordError,
    isProcessing,
  };
}
