// src/LoginPage.js
import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import uselogin from '../../../hooks/useLogIn';
import './styles.css'; 
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const {
    handleSubmit,
    username,
    setUsername,
    usernameError,
    email,
    setEmail,
    password,
    setPassword,
    passwordError,
    isProcessing,
  } = uselogin();

  const navigate = useNavigate();
  const login = useLogin();
  const notify = useNotify();
  const [showPassword, setShowPassword] = useState(false);

  const handleEyeClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-card">
      <h1 className="login-title">LOGIN</h1>
      <h2 className="login-title-h2">Welcome Back To Clock Session</h2>
      <form onSubmit={handleSubmit} className='form'>
        <label htmlFor="email" className="input-label">EMAIL</label>
        <TextField
          id="email"
          className="input-field"
          placeholder="Enter your e-mail here"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          error={usernameError !== ''}
          InputProps={{
  disableUnderline: false,
  
}}


        />

        <label htmlFor="password" className="input-label">PASSWORD</label>
        <div className="password-wrapper">
          <TextField
            id="password"
            className="password-input input-field"
            placeholder="Enter your password here"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            error={passwordError !== ''}
            InputProps={{
  disableUnderline: false,
}}


          />
          <img
            src={showPassword ? '/images/Eye.png' : '/images/Eye-blue.png'}
            alt="Visibility"
            className="eye-button"
            onClick={handleEyeClick}
          />
        </div>

        <div className="remember-me">
          <FormControlLabel
            control={<Checkbox className="remember-me-checkbox" />}
            label="Remember me"
          />
        </div>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="login-button"
        
        >
          {isProcessing ? 'Processing ...' : 'Login'}
        </Button>
      </form>
      <div className="forgot-password">
        <p className="forgot-password p">
          Forgot your password?{" "}
          <span 
          onClick={() => navigate("/app/forgotpassword")}
          >
          Reset here
          </span>{" "}
        </p>
      </div>
      <div className="horizontal-line"></div>
      <div className="signup-text">
        <p>
          Don't have an account?{" "}
          <button className="SignUpButton" 
          onClick={() => navigate("/app/signup")}
          >
           Sign Up
           </button>
        </p>
      </div>
      <Notification />
    </div>
  );
};

export default Login;
