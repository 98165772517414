import './style.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { ImageField } from 'react-admin';
export default function Layout({ component }) {
  return (
    <div className="auth-screen1">
      <div className="auth-screen-header1">
        <img src="/images/clocksession-logo.png" alt="logo" />
      </div>
      <div className="auth-screen-form-container1">
        <div className="auth-screen-card-container">{component}</div>

        <div className="copyright-container1">
          <p className="label-name">ClockSession</p>
          <p>
            ©{new Date().getFullYear()} All Rights Reserved by Mechlin
            Technologies
          </p>
        </div>
      </div>
    </div>
  );
}