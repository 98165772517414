import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  // dsn: process.env.REACT_APP_SENTRY_DSN !== undefined ? process.env.REACT_APP_SENTRY_DSN: "",
  dsn:
    process.env.REACT_SENTRY_DSN !== undefined
      ? process.env.REACT_SENTRY_DSN
      : '',
  environment:
    process.env.ENVIRONMENT !== undefined
      ? process.env.ENVIRONMENT
      : 'development',

  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
console.log(process.env.REACT_APP_SENTRY_DSN);
reportWebVitals();
