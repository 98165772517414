import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectArrayInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  AutocompleteInput,
  NumberInput,
  FormHelperText,
} from 'react-admin';
import { CustomToolbar } from '../../components/Toolbar';
import { Drawer } from '@material-ui/core';
import './style.css';
const optionRenderer = (choice) => {
  return `${choice.name}`;
};

export const CreatePrice = (props) => {
  let { product_id } = props;
  console.log(`CreatePrice`, product_id);

  return (
    <>
     <div className="create-price-container">
      <Create resource="payments/price">
        <SimpleForm>
          <NumberInput
            label="Amount"
            source="unit_amount"
            helperText="A positive integer in cents (or 0 for a free plan) representing how much to charge on a recurring basis."
          />
          <TextInput
            label="Nickname"
            source="nickname"
            helperText="A brief description of the price, hidden from customers."
          />
          <BooleanInput
            label="Active"
            source="active"
            helperText="Whether the price can be used for new purchases."
            defaultValue={true}
          />
          <NumberInput
            label="Interval Count"
            source="interval_count"
            helperText="The number of intervals between subscription billings.
             For example, \n interval=month and interval_count=3 bills every 3 months. Maximum of one year interval allowed (1 year, 12 months, or 52 weeks)."
          />
          <SelectInput
            label="Interval"
            source="recurring.interval"
            helperText="Specifies billing frequency. Either day, week, month or year"
            choices={[
              { id: 'year', name: 'Year' },
              { id: 'month', name: 'Month' },
            ]}
            
          />

          <SelectInput
            label="Currency"
            source="currency"
            choices={[
              { id: 'USD', name: 'USD' },
              { id: 'INR', name: 'INR' },
              { id: 'AUD', name: 'AUD' },
            ]}
          />

          <SelectInput
            label="Billing Scheme"
            source="billing_scheme"
            choices={[
              { id: 'per_unit', name: 'Per Unit' },
              { id: 'tiered', name: 'Tiered' },
            ]}
          />

          <SelectInput
            label="Recurring Interval"
            source="recurring_interval"
            choices={[
              { id: 'day', name: 'Day' },
              { id: 'week', name: 'Week' },
              { id: 'month', name: 'Month' },
            ]}
          />
          {/* {/* <SelectInput
            label="Type"
            source="type"
            choices={[
              { id: "day", name: "Day" },
              { id: "week", name: "one_time" },
              { id: "Month", name: "Month" },
            ]} */}
          {/* <TextInput source="type"
            label="Type"
/> */}
          <SelectInput
            source="type"
            label="Type"
            choices={[
              { id: 'one_time', name: 'One Time' },
              { id: 'recurring', name: 'Recurring' },
            ]}
          />

          {product_id ? (
            <TextInput
              source="product"
              defaultValue={product_id}
              style={{ display: 'none' }} // hide the field
            />
          ) : (
            <>
              <ReferenceInput
                source="product"
                reference="payments/product"
                fullWidth
              >
                <AutocompleteInput optionText={optionRenderer} />
              </ReferenceInput>
            </>
          )}
        </SimpleForm>
      </Create>
      </div>
    </>
  );
};
