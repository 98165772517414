import * as React from 'react';
import {
  Datagrid,
  DateField,
  TextField,
  List,
  useResourceContext,
  BooleanField,
} from 'react-admin';

const ResourceName = () => {
  const resource = useResourceContext();
  console.log('>>>>>', resource);
  return <>{resource}</>;
};

export const ListStripeProduct = () => (
  <List>
    <>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="description" />
        <BooleanField source="exists_in_stripe" />
        <BooleanField source="active" />
        <BooleanField source="livemode" />
      </Datagrid>
    </>
  </List>
);
