import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
// import { Grid } from '@mui/material';

export const CreateStripeProduct = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" fullWidth />
      <BooleanInput source="active" fullWidth />
      <BooleanInput source="shippable" fullWidth />
    </SimpleForm>
  </Create>
);
