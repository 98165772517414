import {
  ChipField,
  ShowButton,
  EditButton,
  BooleanField,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  Datagrid,
  List,
  useShowContext,
} from 'react-admin';
import './index.css';

export const ListPlan = (props) => {
  // ...Existing code...

  return (
    <List {...props} sort={{ field: 'plan_name', order: 'ASC' }}>
      <Datagrid>
        <TextField
          source="plan_name"
          label={<span className="boldLabel"> Plan Name </span>}
        />
        <TextField
          source="duration"
          label={<span className="boldLabel"> Trial Duration (in Days)</span>}
        />
        {/* <TextField
          source="monthly_price"
          label={<span className="boldLabel"> Monthly Price($)</span>}
        /> */}
        {/* <TextField
          source="annual_price"
          label={<span className="boldLabel"> Annual Price($)</span>}
        /> */}
        <ReferenceArrayField
          label={<span className="boldLabel">Features</span>}
          reference="features"
          source="features"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <BooleanField
          source="is_active"
          label={<span className="boldLabel">Active Status</span>}
        />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};
