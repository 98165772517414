import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotify } from 'react-admin';
import { toast } from 'react-toastify';

export default function useSignup() {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [usernameError, setUsernameError] = useState('');

  const commonEmailProviders =['gmail.com', 'yahoo.com', 'outlook.com', 'aol.com', 'protonmail.com', 'zoho.com', 'mail.com', 'gmx.com']
  ;

  const validateEmailProvider = (email) => {
    const emailParts = email.split('@');
    const emailProvider = emailParts[1];
    return commonEmailProviders.includes(emailProvider);
  };




  const passwordValidator = (val) => {
    var lowerCaseLetters = /[a-z]/g;
    var upperCaseLetters = /[A-Z]/g;
    var numbers = /[0-9]/g;
    var specialCharacters = /[!@#$%^&*]/g;
  
    if (!val.match(lowerCaseLetters) || !val.match(upperCaseLetters) || !val.match(numbers) || val.length < 8 || !val.match(specialCharacters)) {
      toast.warn('Password must contain a lower and upper case, a number, and a special character !@#$%^&*');
      return ['Password must contain a lower and upper case, a number, and a special character !@#$%^&*'];
    }
  
    return [];
  };
  

  const validationName = (val) => {
    let Errors = [];
    var numbers = /[0-9]/g;
    var spacialChar = /[!@#$%^&*,.;:'"]/g;
    if (val.match(numbers) || val.match(spacialChar)) {
      Errors.push("Name can't include a Number and !@#$%^&*");
    }

    return Errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const usernameErrors = validationName(username);
    if (usernameErrors.length > 0) {
      setUsernameError(usernameErrors.join(', '));
      setIsProcessing(false);
      return;
    } else {
      setUsernameError('');
    }

    const passwordErrors = passwordValidator(password);
    if (passwordErrors.length > 0) {
      setPasswordError(passwordErrors.join(', '));
      setIsProcessing(false);
      return;
    } else {
      setPasswordError('');
    }
    const userData = {
      fullname: username,
      email: email,
      password: password,
    };
    if (validateEmailProvider(email)) {
      const invalidEmailProvider = email.split('@')[1];
      setEmailError(`Please enter a different email account provider. This form does not accept addresses from ${invalidEmailProvider}.`);
      setIsProcessing(false);
      toast.error(`Please enter a different email address. This form does not accept addresses from ${invalidEmailProvider}.`);
      return;
    } else {
      setEmailError('');
    }
    console.log(userData);
    axios
      .post('/api/tenant-auth/otp/', userData)
      .then((res) => {
        console.log(res);
        localStorage.setItem('userData', JSON.stringify(userData));
        setIsProcessing(false);

        if (res.data.data.email_verified === false) {
          navigate('/app/signup/verify-email');
        } else {
          navigate('/app/signup/organisation-detail');
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data && err.response.data.email) {
          toast.error(err.response.data.email[0], 'warning');
        } else {
          //   toast.warning('An error occurred', 'warning');
        }
        setIsProcessing(false);
      });
  };

  return {
    handleSubmit,
    username,
    setUsername,
    usernameError,
    email,
    setEmail,
    password,
    setPassword,
    passwordError,
    isProcessing,
  };
}
