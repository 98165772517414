// src/LoginPage.js
import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import {
  makeStyles,
  Card,
  CardContent,
  Button,
  TextField,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import axios from 'axios';
import uselogin from '../../../hooks/useLogIn';

const Sentry = () => {
  function debug() {
    console.log('Sentry');
    let result = 100 / 0;
    console.log(result);
    throw new Error('This is a simulated error.');
  }
  return (
    <>
      <h1>This is Debug page</h1>
      <button onClick={debug}>Debug</button>
    </>
  );
};

export default Sentry;
