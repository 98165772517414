// src/OTPVerificationPage.js
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  makeStyles,
  Card,
  CardContent,
  Button,
  Typography,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles({
  card: {
    maxWidth: 432,
    margin: 'auto',
    marginTop: 78,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    width: '100%',
    marginTop: 16,
    borderRadius: 0,
    cursor: 'pointer',
  },
  title: {
    textAlign: 'center',
  },
});

const emailValidator = (val) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(\.+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(val);
};

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [mailError, setMailError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    let forgetData = {
      email: email,
      redirecturl: `${window.location.origin}/ResetPassword/`,
    };
    if (!email.trim()) {
      setMailError('Please enter an email');
    } else if (!emailValidator(email)) {
      setMailError('Please enter a valid email');
    } else {
      setMailError('');
      axios
        .post(`/api/tenant-auth/forgot_password/`, forgetData)
        .then((res) => {
          console.log(res);
          if (res.data.result === 'false') {
            toast.warn('Email not exist');
          }
          if (res.data.status === 'Success') {
            toast.success(
              'Reset password link sent to your email please check your mail',
            );
          }
          setIsProcessing(false);
        })
        .catch((err) => {
          console.log(err.response);
          toast.error(err.response.data.error);
          setIsProcessing(false);
        });
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h4" className={classes.title}>
          Forgot Password?
        </Typography>
        <p className="emailConfirmation">
          Enter the email address you used when you joined and we will send you
          instructions to reset your password.
        </p>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            error={mailError !== ''}
            helperText={mailError}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Submit
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default ForgotPassword;
