import { useNavigate } from 'react-router-dom';

import { Toolbar, SaveButton, Button } from 'react-admin';

export const CustomToolbar = ({ cancelPath, ...props }) => {
  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate(`/${cancelPath}`);
  };

  return (
    <Toolbar redirect="list">
      <SaveButton label="Save" />

      <Button label="Cancel" onClick={handleCancelClick} />
    </Toolbar>
  );
};
