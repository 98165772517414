import {
  Create,
  Show,
  SimpleForm,
  TextField,
  DateField,
  NumberField,
  ReferenceField,
  AutocompleteField,
  ReferenceArrayField,
  BooleanField,
  useShowContext,
  SimpleShowLayout,
  ChipField,
  TabbedShowLayout,
  PasswordInput,
  Datagrid,
  ReferenceManyField,
  List,
  BulkDeleteButton,
  BulkExportButton,
  SearchInput,
  TextInput,
  SelectInput,
  WithRecord,
  FilterLiveSearch,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
// import { Grid } from '@mui/material';
import { LoginAsUserButton } from '../../components/ActionButtons/LoginAsUserButton';

const TenantBulkActionButtons = () => (
  <>
    <BulkExportButton />
    {/* default bulk delete action */}
    <BulkDeleteButton />
  </>
);

const tenantFilters = [<FilterLiveSearch source="q" alwaysOn />];
export const ShowTenant = (props) => {
  const {
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    error, // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onError` side effect.
    isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
    isLoading, // boolean that is true until the record is available for the first time
    record, // record fetched via dataProvider.getOne() based on the id from the location
    refetch, // callback to refetch the record via dataProvider.getOne()
    resource, // the resource name, deduced from the location. e.g. 'posts'
  } = useShowContext();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error!</div>;
  }

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="environment" fullWidth />
          <TextField source="subdomain" fullWidth />
          <TextField source="url" fullWidth />
          <ReferenceField
            source="organisation"
            reference="organisation"
            fullWidth
          >
            <ChipField source="name" />
          </ReferenceField>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="database">
          <TextField source="database.id" fullWidth />
          <TextField source="database.engine" fullWidth />
          <TextField source="database.host" fullWidth />
          <TextField source="database.username" fullWidth />
          <TextField source="database.password" type="password" fullWidth />
          <TextField source="database.port" fullWidth />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Organisation">
          <ReferenceField
            source="organisation"
            reference="organisation"
            link={false}
          >
            <SimpleShowLayout>
              <TextField source="id" label="id" link={'show'} fullWidth />
              <TextField source="name" label="name" fullWidth />
              <TextField source="size" fullWidth />
              <TextField source="describe_your_company" fullWidth />
              <TextField source="registration_number" fullWidth />
              <TextField source="address" fullWidth />
              <TextField source="system_owner" fullWidth />
              <TextField source="address" fullWidth />
              <TextField source="week_start_on" fullWidth />
              <TextField source="timezone" fullWidth />
              <TextField source="domain" fullWidth />
            </SimpleShowLayout>
          </ReferenceField>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Tenant Users">
          <WithRecord
            render={(record) => (
              <span>
                {record.author}
                <ReferenceManyField
                  reference="tenants-core-user"
                  target="tenant"
                >
                  <List
                    filters={tenantFilters}
                    filter={{ tenant: record.id }}
                    sort={{ field: 'id', order: 'DSC' }}
                  >
                    <Datagrid bulkActionButtons={<TenantBulkActionButtons />}>
                      <TextField source="id" />
                      <TextField source="name" />
                      <TextField source="email" />
                      <TextField source="username" />
                      <TextField source="is_active" />
                      <TextField source="screenshots" />
                      <TextField source="screenshot_active" />
                      <TextField source="activated" />
                      <TextField source="status" />
                      <LoginAsUserButton tenantId={record.id} />
                    </Datagrid>
                  </List>
                </ReferenceManyField>
              </span>
            )}
          />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
