import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { textAlign } from '@mui/system';

export default function useOnboardingflow() {
  const navigate = useNavigate();
  const savedData = localStorage.getItem('userData');
  const userData = savedData ? JSON.parse(savedData) : {};
  const [isProcessing, setIsProcessing] = useState(false);

  const [otp, setOTP] = useState(['', '', '', '', '', '']);
  const otpFields = useRef([]);
  const [resendDisabled, setResendDisabled] = useState(false);
  const timerRef = useRef(null);
  const [countdownValue, setCountdownValue] = useState(180);
  const [otpcount, setOtpCount] = useState(
    localStorage.getItem('otpCount')
      ? parseInt(localStorage.getItem('otpCount'))
      : 0,
  );
  const email = userData.email;

  const startCountdown = () => {
    let countdown = 180;
    setCountdownValue(countdown);
    setResendDisabled(true);

    timerRef.current = setInterval(() => {
      countdown -= 1;
      setCountdownValue(countdown);
      if (countdown <= 0) {
        clearInterval(timerRef.current);
        setResendDisabled(false);
      }
    }, 1000);
  };

  useEffect(() => {
    startCountdown();

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  useEffect(() => {
    // Fetch and set the initial value of otpcount from localStorage based on email ID
    const otpCounts = JSON.parse(localStorage.getItem('otpCounts')) || {};
    const storedOtpCount = otpCounts[email] || 0;
    setOtpCount(parseInt(storedOtpCount));
  }, [email]);

  const handleChange = (index, event) => {
    const newOTP = [...otp];
    newOTP[index] = event.target.value.replace(/\D/, '');

    // Move to the next input field on entering a digit
    if (index < otp.length - 1 && event.target.value.length === 1) {
      otpFields.current[index + 1].focus();
    }

    // Move to the previous input field on removing a digit
    if (index > 0 && event.target.value.length === 0) {
      otpFields.current[index - 1].focus();
    }

    setOTP(newOTP);
  };

  const handlePaste = (event) => {
    event.preventDefault();

    const pastedData = event.clipboardData.getData('Text');
    const pastedCharacters = pastedData.split('');

    // Filter out non-digit characters
    const pastedDigits = pastedCharacters.filter((char) => /\d/.test(char));

    if (pastedDigits.length === 0) {
      toast.warn('Please paste only digits');
      return;
    }

    // Fill the available boxes with pasted digits
    const newOTP = Array(6)
      .fill(null)
      .map((_, index) => {
        return index < pastedDigits.length
          ? parseInt(pastedDigits[index], 10)
          : null;
      });

    setOTP(newOTP);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const user_otp = otp.join('');
    if (!user_otp || user_otp.trim() === '') {
      toast.warning('Please enter the OTP', { autoClose: 3000 });
      setIsProcessing(false);
      return;
    }

    console.log(user_otp);
    axios
      .post('/api/tenant-auth/otp/verify_otp/', {
        email: email,
        generated_otp: user_otp,
      })
      .then((response) => {
        toast.success('Email verified successfully');
        navigate('/app/signup/organisation-detail');
        console.log('OTP verified successfully.');
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.email) {
          toast.error(err.response.data.email[0], 'warning');
        } else {
          toast.warning('OTP does not match', 'warning');
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleResendOTP = () => {
    if (otpcount >= 5) {
      toast.warn('You have already exceeds the limit!');
      return;
    }
    axios
      .post('/api/tenant-auth/otp/resend_otp/', {
        email: email,
      })
      .then((response) => {
        console.log(response.data);
        toast.success('OTP sent');
        const newCount = response.data.data.resend_count;
        const otpCounts = JSON.parse(localStorage.getItem('otpCounts')) || {};
        otpCounts[email] = newCount;
        localStorage.setItem('otpCounts', JSON.stringify(otpCounts));
        setOtpCount(newCount);
        console.log('OTP sent successfully.');

        startCountdown();
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.email) {
          toast.error(err.response.data.email[0], 'warning');
        } else {
          toast.warning('An error occurred', 'warning');
        }
      });
  };

  useEffect(() => {
    const email = userData.email;

    if (!email) {
      navigate('/app/signup');
      return;
    }
  }, []);

  return {
    email,
    otp,
    otpFields,
    otpcount,
    handleChange,
    handlePaste,
    countdownValue,
    resendDisabled,
    handleSubmit,
    handleResendOTP,
    isProcessing,
  };
}
