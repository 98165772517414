// Import necessary libraries
import React from 'react';
import { List, Datagrid, TextField, DateField, FunctionField, Link, BooleanField } from 'react-admin';

// Define the ReleaseList component
const ReleaseList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" label="ID" />
            <TextField source="version" label="Version" />
            <DateField source="release_date" label="Release Date" />
            <BooleanField source="is_active" label="Active" />
            <TextField source="changelog" label="Changelog" />
            <TextField source="release_notes" label="Release Notes" />
            <FunctionField
                label="Download Links"
                render={record => (
                    <div>
                        {record.artifacts.map(artifact => (
                            <div key={artifact.id}>
                                <Link to={artifact.file} target="_blank">
                                    {`${artifact.operating_system} (${artifact.architecture})`}
                                </Link>
                            </div>
                        ))}
                    </div>
                )}
            />
        </Datagrid>
    </List>
);

export default ReleaseList;
