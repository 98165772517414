import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  Datagrid,
  BooleanInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
// import { Grid } from '@mui/material';

export const CreateOrganisation = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <SelectInput
        source="size"
        fullWidth
        choices={[
          { id: '1-25', name: '1-25' },
          { id: '26-50', name: '26-50' },
          { id: '51-100', name: '51-100' },
          { id: '101-500', name: '101-500' },
          { id: '101-500', name: '101-500' },
          { id: '501-1000', name: '1000+' },
        ]}
      />
      <SelectInput
        source="system_owner"
        fullWidth
        choices={[
          { id: 'Company', name: 'Company' },
          { id: 'Team Members', name: 'Team Members' },
        ]}
      />
      <SelectInput
        source="week_start_on"
        fullWidth
        choices={[
          { id: 'Monday', name: 'Monday' },
          { id: 'Tuesday', name: 'Tuesday' },
          { id: 'Wednesday', name: 'Wednesday' },
          { id: 'Thursday', name: 'Thursday' },
          { id: 'Friday', name: 'Friday' },
          { id: 'Saturday', name: 'Saturday' },
          { id: 'Sunday', name: 'Sunday' },
        ]}
      />
      <SelectInput
        source="timezone"
        fullWidth
        choices={[
          { id: 'UTC', name: 'UTC' },
          { id: 'Asia/Kolkata', name: 'Asia/Kolkata' },
        ]}
      />
    </SimpleForm>
  </Create>
);
