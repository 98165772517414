// src/OTPVerificationPage.js
import React, { useState, useRef, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import {
  makeStyles,
  Card,
  CardContent,
  Button,
  Typography,
} from '@material-ui/core';

import { useStyles } from './styles';
import useOnboardingflow from '../../../hooks/useOnboardingflow';

const OtpScreen = () => {
  const {
    email,
    otp,
    otpFields,
    otpcount,
    handleChange,
    handlePaste,
    countdownValue,
    resendDisabled,
    handleSubmit,
    handleResendOTP,
    isProcessing,
  } = useOnboardingflow();

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h4" className={classes.title}>
          OTP Verification
        </Typography>
        <p class="emailConfirmation">
          We emailed you the six-digit code to{' '}
          <span className={classes.emailText}>{email}</span> enter the code
          below to confirm your email address.
        </p>
        <form onSubmit={handleSubmit}>
          <div className={classes.otpContainer}>
            {otp.map((value, index) => (
              <input
                key={index}
                className={classes.otpInput}
                type="text"
                maxLength={1}
                value={value}
                onChange={(e) => handleChange(index, e)}
                ref={(el) => (otpFields.current[index] = el)}
                onPaste={handlePaste}
              />
            ))}
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            {isProcessing ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Verify OTP'
            )}
          </Button>
        </form>
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleResendOTP}
          disabled={resendDisabled}
        >
          Resend OTP {countdownValue > 0 && `(${countdownValue} seconds)`}
        </Button>
        <Typography
          variant="subtitle1"
          align="center"
          style={{ color: '#555', fontWeight: 'bold' }}
        >
          Remaining Attempts: {5 - otpcount}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default OtpScreen;
