import React from 'react';

import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectArrayInput,
  DateField,
  DateInput,
} from 'react-admin';

import { CustomToolbar } from '../../components/Toolbar';

export const EditActivePlan = (props) => {
  const cancelPath = 'active-subscription';
  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<CustomToolbar cancelPath={cancelPath} />}
        redirect="list"
      >
        <TextInput source="custom_price" />
        <ReferenceInput source="feature" reference="features">
          <SelectArrayInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <DateInput
          source="expiry_date"
          label={<span className="boldLabel">Expiry Date</span>}
        />

        <BooleanInput source="is_active" />
      </SimpleForm>
    </Edit>
  );
};
