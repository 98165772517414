import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  Datagrid,
  BooleanInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
// import { Grid } from '@mui/material';
const optionRenderer = (choice) => {
  return `${choice.name} - Id: ${choice.id}`;
};
export const CreateCustomer = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="phone_number" fullWidth />
      <TextInput source="email" fullWidth />
      <TextInput source="address" fullWidth />
      <ReferenceArrayInput
        source="organisation"
        reference="organisation"
        fullWidth
      >
        <AutocompleteArrayInput optionText={optionRenderer} />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
