import {
  ChipField,
  BooleanField,
  TextField,
  ReferenceField,
  SingleFieldList,
  SimpleShowLayout,
  Show,
  DateField,
  ReferenceArrayField,
} from 'react-admin';

export const ShowActivePlan = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField
          label={<span className="boldLabel">Customer</span>}
          source="customer"
          reference="customer"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField
          label={<span className="boldLabel">Subscription</span>}
          source="subscription"
          reference="subscription"
          link={false}
        >
          <TextField source="plan_name" />
        </ReferenceField>

        <ReferenceField
          label={<span className="boldLabel">Organisation</span>}
          source="organisation"
          reference="organisation"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>

        <ReferenceArrayField
          label={<span className="boldLabel">Features</span>}
          reference="features"
          source="feature"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="created_at" />
        <TextField source="modified_at" />
        <DateField source="start_date" />
        <DateField source="expiry_date" />
        <BooleanField source="is_active" />
      </SimpleShowLayout>
    </Show>
  );
};
