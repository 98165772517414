import * as React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, SelectInput, RadioButtonGroupInput, DateTimeInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import { useState } from 'react';
import { ReferenceInput, AutocompleteInput, BooleanInput } from 'react-admin';
import { useNotify,useRedirect } from 'react-admin';
import { required } from 'react-admin';
import axios from 'axios';
const CreateCoupon = (props) => {
    const [discountType, setDiscountType] = useState('percent_off');
    const [selectedDuration, setSelectedDuration] = useState('null');
    const [limitDateRange, setLimitDateRange] = useState(false);
    const [limitRedemptionCount, setLimitRedemptionCount] = useState(false);
    const [timesredeem, setTimesRedeem] = useState(0);
    const [applyToSpecificCoupon, setApplyToSpecificCoupon] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('USD');
    const [amountOff, setAmountOff] = useState(null);
    const [percentage, setPercentage] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [durationInMonths, setDurationInMonths] = useState(0);
    const [maxRedemptions, setMaxRedemptions] = useState(null);
    const [redeemBy, setRedeemBy] = useState(null);
    const [metadata, setMetadata] = useState([]);
    const [couponid, setcouponid] = useState("");
    const notify = useNotify();
    const redirect = useRedirect();
  
    const handleSelectChange = (event) => {
        setSelectedDuration(event.target.value);
    };
    const [name, setName] = useState('');

    const handleDiscountTypeChange = (event) => {
        setDiscountType(event.target.value);
    };

    const handleLimitDateRangeChange = (event) => {
        setLimitDateRange(event.target.checked);
    };

    const handleLimitRedemptionCountChange = (event) => {
        setLimitRedemptionCount(event.target.checked);
    };
    const handletimesredeem = (event) => {
        setTimesRedeem(event.target.value);
    };

    const handleApplyToSpecificCouponChange = (event) => {
        setApplyToSpecificCoupon(event.target.checked);
    };

    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
    };

    const handleAmountOffChange = (event) => {
        setAmountOff(event.target.value !== '' ? event.target.value : null);
    };
    const handlePercentChange = (event) => {
        setPercentage(event.target.value !== '' ? event.target.value : null);
    };
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handlecouponid = (event) => {
        setcouponid(event.target.value);
    };

    const handleCurrencyInputChange = (event) => {
        setCurrency(event.target.value !== '' ? event.target.value : null);
    };

    const handleDurationInMonthsChange = (event) => {
        setDurationInMonths(event.target.value !== '' ? event.target.value : null);
    };

    const handleMaxRedemptionsChange = (event) => {
        setMaxRedemptions(event.target.value !== '' ? event.target.value : null);
    };

    const handleRedeemByChange = (event) => {
        setRedeemBy(event.target.value !== '' ? event.target.value : null);
    };

    const DurationInput = ({ handleSelectChange, selectedDuration }) => {
        // State to track the number of months for "Multiple Months" option
        const [numMonths, setNumMonths] = useState(null);

        return (
            <div>
                {/* Render NumberInput only when "Multiple Months" is selected */}
                {selectedDuration === 'multiple' && (
                    <NumberInput
                        source="duration_in_months"
                        label="Number of Months"
                        onChange={(event, value) => setNumMonths(value)}
                    />
                )}
            </div>
        );
    };

    const optionRenderer = (choice) => {
        return `${choice.name}`;
    };

    const handleSubmit = async (formData) => {
    
        const payload = {
            ...formData,
             name:name,
             coupon_id:couponid,

            amount_off: amountOff,
            currency: selectedCurrency,
            percent_off:percentage,
            duration:selectedDuration,
            duration_in_months: durationInMonths,
            metadata:metadata,
            max_redemptions: maxRedemptions,
            redeem_by: redeemBy,
            times_redeemed:timesredeem,
            valid:true
  
  
        };
        try {
            // Perform the API call here
            const response = await axios.post('/api/coupon/', payload, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any other headers if required
                },
                body: JSON.stringify(payload),
            });
    
            if (response.status === 201) {
                redirect('/dashboard/coupon');
                
                notify('Coupon created successfully', 'success');
            } 
            // Add any additional logic after successful creation
        } catch (error) {
            // Handle API validation errors
            if (error.response && error.response.data && error.response.data.name) {
                notify(error.response.data.name[0], 'error');
            } else {
                console.error('Error creating coupon:', error.message);
            }
        }
        
    };
    return (
        <Create   {...props}>
            <SimpleForm onSubmit={handleSubmit}>
                <TextInput source="name" label="Name" onChange={handleNameChange} validate={required()}/>
                <TextInput source="coupon_id" label="ID (Optional)"  onChange={handlecouponid}/>
                <RadioButtonGroupInput
                    source='dummy'
                    label="Discount Type"
                    choices={[
                        { id: 'percent_off', name: 'Percentage Discount' },
                        { id: 'fixed', name: 'Fixed Amount Discount' },
                    ]}
                    defaultValue="percent_off"
                    onChange={handleDiscountTypeChange}
                />
                {discountType === 'percent_off' && (
                    <div style={{ position: 'relative' }}>
                        <NumberInput source="percent_off" label="Percentage Off" onChange={handlePercentChange}/>
                        <span style={{ position: 'absolute', top: '50%', right: '45px', transform: 'translateY(-50%)' }}>%</span>
                    </div>
                )}
                {discountType === 'fixed' && (
                    <div style={{ position: 'relative' }}>
                        <div style={{ display: 'flex' }}>
                        <NumberInput source="amount_off" label="Discount amount" onChange={handleAmountOffChange} />
                            <SelectInput
                                source="currency"
                                label="Currency"
                                choices={[
                                    { id: 'USD', name: 'USD' },
                                    { id: 'INR', name: 'INR' },
                                   
                                ]}
                                onChange={handleCurrencyChange}
                                defaultValue="USD"
                                style={{ marginLeft: '10px', width: '100px' }}
                            />
                        </div>
                        <span style={{ position: 'absolute', top: '50%', right: '45px', transform: 'translateY(-50%)' }}>{selectedCurrency === 'USD' ? '$' : '₹'}</span>
                    </div>
                )}

                <SelectInput
                    source="duration"
                    label="Duration"
                    choices={[
                        { id: 'forever', name: 'Forever' },
                        { id: 'once', name: 'Once' },
                        { id: 'multiple', name: 'Multiple Months' },
                    ]}
                    onChange={handleSelectChange}
                    // defaultValue="forever"
                />

                <DurationInput handleSelectChange={handleSelectChange} selectedDuration={selectedDuration} />
                <BooleanInput source="apply_to_specific_coupon" label="Apply to Specific Products" onChange={handleApplyToSpecificCouponChange} />
                {applyToSpecificCoupon && (
                    <ReferenceInput source="metadata" reference="payments/product" fullWidth>
                    <AutocompleteInput 
                        optionText={optionRenderer}  
                        onChange={(event, values) => {
                            // Extract the primary keys from the selected items
                            const selectedIds = values.map(item => item.id);
                            // Set metadata to the array of primary keys
                            setMetadata(selectedIds);
                        }}
                        multiple // Enable multiple selection
                    />
                </ReferenceInput>
                
                )}

                <div>
                    <h4>Redemption Limits</h4>
                    <div>
                        <label>
                            <input type="checkbox" checked={limitDateRange} onChange={handleLimitDateRangeChange} />
                            Limit the date range when customers can redeem this coupon
                        </label>
                        {limitDateRange && (
                            <div>
                               <DateTimeInput source="redeem_by" label="Redeem By" onChange={handleRedeemByChange} />
                            </div>
                        )}
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" checked={limitRedemptionCount} onChange={handleLimitRedemptionCountChange} />
                            Limit the total number of times this coupon can be redeemed
                        </label>
                        {limitRedemptionCount && (
                            <div>
                                {/* Render additional fields for redemption count limit */}
                                <NumberInput source="times_redeemed" label="" onChange={handletimesredeem}/>
                                <span style={{ position: 'relative', top: '25px', right: '90px', transform: 'translateY(-50%)' }}>time</span>
                            </div>
                        )}
                    </div>
                </div>
                <BooleanInput source="valid" label="Valid" />
            </SimpleForm>
        </Create>
    );
};

export default CreateCoupon;
