import React from 'react';

import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectArrayInput,
  AutocompleteInput,
} from 'react-admin';

import { CustomToolbar } from '../../components/Toolbar';
const optionRenderer = (choice) => {
  return `${choice.name}`;
};
export const EditPlan = (props) => {
  const cancelPath = 'dashboard/subscription';
  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<CustomToolbar cancelPath={cancelPath} />}
        redirect="list"
      >
        <TextInput label="Plan Name" source="plan_name" />
        <TextInput source="duration" fullWidth />
        {/* <TextInput source="monthly_price" fullWidth />
        <TextInput source="annual_price" fullWidth /> */}
        <ReferenceInput source="features" reference="features">
          <SelectArrayInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source="product" reference="payments/product" fullWidth>
          <AutocompleteInput optionText={optionRenderer} />
        </ReferenceInput>
        <BooleanInput source="is_active" fullWidth />
      </SimpleForm>
    </Edit>
  );
};
