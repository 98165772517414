import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';

export default function useOrganisation() {
  const navigate = useNavigate();
  const [companyname, setCompanyname] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [orgAddress, setOrgAddress] = useState('');
  const [regnumber, setRegNumber] = useState('');
  const [verifiedData, setVerifiedData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [signuperror, setSignupError] = useState(' ');

  const closeAlert = () => {
    setShowAlert(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const userData = {
      name: verifiedData.fullname,
      email: verifiedData.email,
      organisation: {
        name: companyname,
        size: companySize,
        describe_your_company: companyType,
        registration_number: regnumber,
        address: orgAddress,
      },
      password: verifiedData.password,
    };

    console.log(userData);

    try {
      const res = await axios.post(`/api/tenant-auth/signup/`, userData);

      console.log(res);

      if (res.data && res.data.redirect_url) {
        window.location.href = res.data.redirect_url;
      }
    } catch (error) {
      console.log('err', error.response.data.error);
      setSignupError(error.response.data.error);
      setShowAlert(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const savedData = localStorage.getItem('userData');
  const userData = savedData ? JSON.parse(savedData) : {};

  useEffect(() => {
    const email = userData.email;

    if (!email) {
      navigate('/app/signup');
      return;
    }
    axios
      .get(`/api/tenant-auth/otp/?email=${email}`)
      .then((res) => {
        console.log(res);
        console.log(res.data.results[0]);

        if (res.data.results[0].email_verified === false) {
          // toast.warning('Your email is not verified. Please verify your email.', 'warning');
          navigate('/app/signup/verify-email');
        } else {
          setVerifiedData(res.data.results[0]);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data && err.response.data.email) {
          toast.error(err.response.data.email[0], 'warning');
        } else {
          toast.error('something went wrong', 'error');
        }
      });
  }, []);

  return {
    handleSubmit,
    companyname,
    companySize,
    companyType,
    setCompanySize,
    setCompanyType,
    setCompanyname,
    orgAddress,
    setOrgAddress,
    regnumber,
    setRegNumber,
    isProcessing,
    showAlert,
    closeAlert,
    signuperror,
  };
}
