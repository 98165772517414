import './style.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { ImageField } from 'react-admin';

const redirectToPrivacyPolicy = () => {
    window.location.href = "https://clocksession.com/privacy-policy/";
  };

  const redirectToTermsOfService = () => {
    window.location.href = "https://clocksession.com/terms-and-conditions/";
  };
export default function AuthenticateLayout({ component, isLoginScreen }) {
  return (
    <div className="auth-screen">
      <div className="auth-screen-header">
        <Link to="/">
          <img src="/images/clocksession-logo.png" alt="clocksession-logo" />
        </Link>
        <div className="about" style={{ fontSize: "18px" }}>
      {isLoginScreen ? (
        <p className='about-content'>Log In to your Clock Session account to track your time and <br/>manage your projects efficiently.</p>
      ) : (
        <p className='about-content'>
          Sign Up for a free Clock Session account to track your time,
          <br />
          manage your projects, and boost your productivity.
        </p>
      )}
    </div>
        <div className="svg-container">
        {isLoginScreen ? (
            <img
              src="/images/clocksession_login.svg"
              alt="Login Preview 1"
              className="Clocksession_preview-svg"
            />
          ) : (
            <img
              src="/images/clocksession-signup.svg"
              alt="Signup Preview 2"
              className="Clocksession_preview-svg"
            />
          )}
        </div>
        <div className="copyright-container">
          <p>
            ©{new Date().getFullYear()} All Rights Reserved by Mechlin
            Technologies
          </p>
        </div>
      </div>
      <div className="auth-screen-form-container">
        <div className="auth-screen-card-container">{component}</div>
        <div className="Policy-services">
        <p className="policy">
            <Link to="#" onClick={redirectToPrivacyPolicy} style={{ textDecoration: 'none', color: 'inherit' }}>
              Privacy Policy
            </Link>
            {" | "}
            <Link to="#" onClick={redirectToTermsOfService} style={{ textDecoration: 'none', color: 'inherit' }}>
              Terms of Service
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
