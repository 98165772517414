import React from 'react';
import { Show, SimpleShowLayout, TextField, NumberField, DateField, BooleanField } from 'react-admin';

const CouponShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="coupon_id" />
            <NumberField source="amount_off" record={props.record} />
            <TextField source="currency" />
            <TextField source="duration" />
            <NumberField source="duration_in_months" record={props.record} />
            <NumberField source="max_redemptions" record={props.record} /> 
            <TextField source="name" />
            <TextField source="metadata" />
            <NumberField source="percent_off" />
            <DateField source="redeem_by" />
            <NumberField source="times_redeemed" />
            <BooleanField source="valid" />
            <TextField source="object" />
            <BooleanField source="livemode" />
            <DateField source="created_at" />
            <DateField source="modified_at" />
            <TextField source="ref" />
            <DateField source="deleted_at" />
            <DateField source="created" />
        </SimpleShowLayout>
    </Show>
);

export default CouponShow;
