import {
  ChipField,
  ShowButton,
  EditButton,
  BooleanField,
  TextField,
  ReferenceField,
  SingleFieldList,
  Datagrid,
  List,
  ReferenceArrayField,
  DateField,
} from 'react-admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export const ListActivePlan = (props) => {
  return (
    <>
      <Card style={{ maxHeight: '100px', overflow: 'auto' }}>
        <CardContent className="boldLabel">
          List of all active plans purchased by the organizations
        </CardContent>
      </Card>
      <List {...props} sort={{ field: 'plan_name', order: 'ASC' }}>
        <Datagrid>
          <ReferenceField
            label={<span className="boldLabel">Customer</span>}
            source="customer"
            reference="customer"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>

          <ReferenceField
            label={<span className="boldLabel">Subscription</span>}
            source="subscription"
            reference="subscription"
            link={false}
          >
            <TextField source="plan_name" />
          </ReferenceField>

          <ReferenceField
            label={<span className="boldLabel">Organisation</span>}
            source="organisation"
            reference="organisation"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>

          <ReferenceArrayField
            label={<span className="boldLabel">Features</span>}
            reference="features"
            source="feature"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>

          <DateField
            source="start_date"
            label={<span className="boldLabel">Purchased Date</span>}
          />
          <DateField
            source="expiry_date"
            label={<span className="boldLabel">Expiry Date</span>}
          />

          <BooleanField
            source="is_active"
            label={<span className="boldLabel">Active Status</span>}
          />
          <ShowButton />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};
