import React from 'react';
import {
  TextField,
  Tab,
  Button,
  useDataProvider,
  useRefresh,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { useMutation } from 'react-query';

export const LoginAsUserButton = (props) => {
  let { tenantId } = props;
  const record = useRecordContext();
  let { id } = record;
  console.log('LoginAsUserButton', record, id);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const { mutate, isLoading } = useMutation(
    ['login_as_user', record ? record.id : null, tenantId],
    () => {
      const data = dataProvider.login_as_user(
        record ? record.id : null,
        tenantId,
      );
      console.log('LoginAsUserButton', data);
      data.then((data) => {
        window.open(data.url, '_blank');
        if (data && data.message) {
          notify(data.message);
        }
        refresh();
      });
      return data;
    },
  );

  return (
    <Button
      variant="contained"
      label="Login As User"
      target="_blank"
      onClick={() => {
        mutate();
        refresh();
      }}
      disabled={isLoading}
    />
  );
};
