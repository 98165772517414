// Data and Auth
// import axios from 'axios';
import drfProvider, {
  tokenAuthProvider,
  fetchJsonWithAuthToken,
} from 'ra-data-django-rest-framework';
import { stringify } from 'query-string';

const BASE_URL = '';
const AUTH_URL = '/api/auth-token/';
export const authProvider = tokenAuthProvider({
  obtainAuthTokenUrl: AUTH_URL,
});
export const dataProvider = drfProvider(
  `${BASE_URL}/api`,
  fetchJsonWithAuthToken,
);

let client = {
  getList: function (resource, params) {
    return dataProvider.getList(resource, params);
  },
  getOne: function (resource, params) {
    return dataProvider.getOne(resource, params);
  },
  getMany: async (resource, params) => {
    if (params.ids.length > 1 && resource == 'organisation') {
      let stringified = stringify(params);
      console.log('getMany', stringified);
      // Use useGetMany for multiple records
      const url = `${BASE_URL}/api/${resource}?${stringified}`;
      console.log('getMany url', url);

      const response = await fetchJsonWithAuthToken(url, { method: 'GET' });
      console.log('getMany url', { data: response.json.results });

      return { data: response.json.results };
    }

    // Fallback to default behavior for a single record
    return dataProvider.getMany(resource, params);
  },
  getManyReference: function (resource, params) {
    return dataProvider.getManyReference(resource, params);
  },
  create: function (resource, params) {
    return dataProvider.create(resource, params);
  },
  update: function (resource, params) {
    return dataProvider.update(resource, params);
  },
  updateMany: function (resource, params) {
    return dataProvider.updateMany(resource, params);
  },
  delete: function (resource, params) {
    return dataProvider.delete(resource, params);
  },
  deleteMany: function (resource, params) {
    return dataProvider.deleteMany(resource, params);
  },
  monitorCompany: function (companyID) {
    return fetchJsonWithAuthToken(
      `${BASE_URL}/api/company-offical/${companyID}/monitor/`,
      { method: 'GET' },
    )
      .then(function (res) {
        return res.json();
      })
      .catch(function (err) {
        console.log(err);
      });
  },
  save_card_for_now: function (customer_id) {
    return fetchJsonWithAuthToken(
      `${BASE_URL}/api/payments/checkout/save_card_for_now/?customer=${customer_id}`,
      { method: 'GET' },
    )
      .then(function (res) {
        console.log('save_card_for_now', res);
        console.log('save_card_for_now', res.json.redirect_url);
        // window.location.href = res.json.redirect_url
        let data = res.json;

        return data;
      })
      .catch(function (err) {
        console.log(err);
      });
  },
  UnMonitorCompany: function (companyID) {
    return fetchJsonWithAuthToken(
      `${BASE_URL}/api/company-offical/${companyID}/unmonitor/`,
      { method: 'GET' },
    )
      .then(function (res) {
        return res.json();
      })
      .catch(function (err) {
        console.log(err);
      });
  },
  login_as_user: (userId, tenantId) => {
    return fetchJsonWithAuthToken(
      `${BASE_URL}/api/tenants-core-user/${userId}/login_as_user/?tenant=${tenantId}`,
      { method: 'GET' },
    )
      .then((res) => {
        return res.json;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default client;
