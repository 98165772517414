import { SimpleForm, Edit, TextInput } from 'react-admin';
import { CustomToolbar } from '../../components/Toolbar';

export const EditFeature = (props) => {
  return (
    <Edit>
      <SimpleForm toolbar={<CustomToolbar cancelPath={'features'} />}>
        <TextInput source="name" />
        <TextInput source="description" />
      </SimpleForm>
    </Edit>
  );
};
