import React from 'react';
import { List, Datagrid, TextField, NumberField, DateField, BooleanField, ShowButton, EditButton, FunctionField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    centeredField: {
        textAlign: 'center', // Align both label and value to the center
    },
});

const CouponList = (props) => {
    const classes = useStyles();

    return (
        <List {...props}>
            <Datagrid>
                <TextField source="name" label="Coupon" />
                <FunctionField
                    label="Percent Off(%)"
                    render={record => record.percent_off !== null ? `${record.percent_off}` : '0'}
                    style={{ textAlign: "center" }}
                />
                <FunctionField
                    label="Amount Off"
                    render={record => record.amount_off !== null ? `${record.amount_off} ${record.currency}` : '0'}
                    style={{ textAlign: "center" }}
                />
                <DateField source="redeem_by" label="Redeem By" />
                <NumberField source="times_redeemed" label="REDEMPTIONS"  style={{textAlign:"center"}}/>
                <BooleanField source="valid" label="Valid" />
                <ShowButton/>
                <EditButton/>
            </Datagrid>
        </List>
    );
};

export default CouponList;
