import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  card: {
    maxWidth: 530,
    margin: 'auto',
    marginTop: 78,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', // Center the content vertically
    flexDirection: 'column', // Display content in a column
  },
  button: {
    width: '100%',
    marginTop: 16,
    borderRadius: 0,
    cursor: 'pointer',
  },
  title: {
    textAlign: 'center',
  },
  otpInput: {
    width: '40px',
    textAlign: 'center',
    marginRight: '10px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '18px',
  },
  otpContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '420px', // Increased width of the container
    margin: 'auto', // Centering the container itself
    marginTop: '35px',
  },
  resendLink: {
    marginTop: 10,
    textDecoration: 'underline',
    cursor: 'pointer',
    textAlign: 'center',
  },

  resendText: {
    color: 'blue', // Changing text color to blue
    textDecoration: 'underline', // Adding underline to the text
  },
  emailConfirmation: {
    maxWidth: '300px' /* Adjust the maximum width as per your design */,
    // overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  emailText: {
    color: 'blue',
  },
});
