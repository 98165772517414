import {
  Create,
  Show,
  SimpleForm,
  TextField,
  BooleanField,
  ReferenceArrayField,
  Datagrid,
  EditButton,
  ShowButton,
  DateInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  useShowContext,
  SimpleShowLayout,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
// import { Grid } from '@mui/material';

export const ShowStripeProduct = (props) => {
  const {
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    error, // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onError` side effect.
    isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
    isLoading, // boolean that is true until the record is available for the first time
    record, // record fetched via dataProvider.getOne() based on the id from the location
    refetch, // callback to refetch the record via dataProvider.getOne()
    resource, // the resource name, deduced from the location. e.g. 'posts'
  } = useShowContext();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error!</div>;
  }
  console.log('resource', resource);

  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <BooleanField source="active" />
        <BooleanField source="livemode" />
        <BooleanField source="exists_in_stripe" />
        <ReferenceArrayField
          label="Prices"
          reference="payments/price"
          source="prices"
        >
          <Datagrid>
            <TextField source="id" />
            <TextField source="type" />
            <TextField source="unit_amount" />
            <TextField source="unit_amount_decimal" />
            <TextField source="currency" />
            <TextField source="billing_scheme" />
            <TextField source="recurring_interval" />
            <TextField source="recurring_interval_count" />
            <TextField source="recurring_usage_type" />
            {/* <TextField source="description" /> */}
            {/* <NumberField source="price" options={{ style: 'currency', currency: 'USD' }} /> */}
            <EditButton />
          </Datagrid>
        </ReferenceArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
