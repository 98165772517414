import {
  Create,
  Show,
  SimpleForm,
  TextField,
  DateField,
  NumberField,
  ReferenceField,
  AutocompleteField,
  ReferenceArrayField,
  BooleanField,
  useShowContext,
  Datagrid,
  List,
  ShowButton,
  EditButton,
  DeleteButton,
  SimpleShowLayout,
  ChipField,
  FilterLiveSearch,
  ReferenceManyCount,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { SaveCardButton } from '../../components/ActionButtons/SaveCardButton';
// import { Grid } from '@mui/material';
const tenantFilters = [<FilterLiveSearch source="q" alwaysOn />];

export const ListTenant = (props) => {
  const {
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    error, // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onError` side effect.
    isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
    isLoading, // boolean that is true until the record is available for the first time
    record, // record fetched via dataProvider.getOne() based on the id from the location
    refetch, // callback to refetch the record via dataProvider.getOne()
    resource, // the resource name, deduced from the location. e.g. 'posts'
  } = useShowContext();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error!</div>;
  }
  console.log('ShowUser', resource);
  console.log('ShowUser', props);

  return (
    <List filters={tenantFilters} filterDefaultValues={{ ordering: '-id' }}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="environment" fullWidth />
        <TextField source="subdomain" fullWidth />
        <TextField source="url" fullWidth />
        <TextField source="database.id" fullWidth />
        <TextField source="database.name" fullWidth />
        <ReferenceManyCount
          label="Total Tenant Users"
          reference="tenants-core-user"
          target="tenant"
          link
        />
        <ReferenceField
          source="organisation"
          reference="organisation"
          fullWidth
        >
          <ChipField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
