import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import {
  makeStyles,
  Card,
  CardContent,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import useOrganisation from '../../../hooks/useOrganisation';

const useStyles = makeStyles({
  card: {
    maxWidth: 400,
    margin: 'auto',
    marginTop: 78,
  },
  button: {
    width: '100%',
    marginTop: 16,
    borderRadius: 0,
  },
  title: {
    textAlign: 'center',
  },
});

const Organisation = () => {
  const {
    handleSubmit,
    companyname,
    companySize,
    companyType,
    setCompanySize,
    setCompanyType,
    setCompanyname,
    orgAddress,
    setOrgAddress,
    regnumber,
    setRegNumber,
    isProcessing,
    showAlert,
    closeAlert,
    signuperror,
  } = useOrganisation();

  const classes = useStyles();
  return (
    <Card className={classes.card}>
      {showAlert && (
        <Dialog
          open={showAlert}
          onClose={closeAlert}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">Error</DialogTitle>
          <DialogContent>
            <Alert severity="warning">{signuperror}</Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <CardContent>
        <Typography variant="h4" className={classes.title}>
          Organisation Details
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Company Name"
            type="text"
            value={companyname}
            onChange={(e) => setCompanyname(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>How many people work at your company</InputLabel>
            <Select
              value={companySize}
              onChange={(e) => setCompanySize(e.target.value)}
            >
              <MenuItem value="1-25">1-25</MenuItem>
              <MenuItem value="26-50">26-50</MenuItem>
              <MenuItem value="51-100">51-100</MenuItem>
              <MenuItem value="101-500">101-500</MenuItem>
              <MenuItem value="101-500">1000+</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Which best describes your company</InputLabel>
            <Select
              value={companyType}
              onChange={(e) => setCompanyType(e.target.value)}
            >
              <MenuItem value="Software Company">Software Company</MenuItem>
              <MenuItem value="Consultancy">Consultancy</MenuItem>
              <MenuItem value="Construction">Construction</MenuItem>
              <MenuItem value="Commerce">Commerce</MenuItem>
              <MenuItem value="Education">Education</MenuItem>
              <MenuItem value="other">other</MenuItem>
            </Select>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={isProcessing} // Disable the button when processing
          >
            {isProcessing ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Start the 14-day free trial'
            )}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default Organisation;
