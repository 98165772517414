import {
  ChipField,
  BooleanField,
  TextField,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  TabbedShowLayout,
  Show,
  WithRecord,
} from 'react-admin';
import './index.css';
import { Drawer, Button } from '@material-ui/core';
import { CreatePrice } from '../Price/CreatePrice';
import { useState } from 'react';

export const ShowPlan = (props) => {
  const [drawerOpen, setOpen] = useState(false);
  // Function to handle button click and open the dialog
  const handleButtonClick = () => {
    setOpen(true);
  };

  // Function to handle dialog close
  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Subscription Details">
          <TextField source="id" />
          <TextField source="created_at" />
          <TextField source="modified_at" />
          <TextField source="deleted_at" />
          <TextField source="plan_name" />
          <TextField source="duration" />
          <TextField source="monthly_price" />
          <TextField source="product" />

          <TextField source="annual_price" />
          <ReferenceArrayField
            label={<span className="boldLabel"> Features</span>}
            reference="features"
            source="features"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <BooleanField source="is_active" />

          <ReferenceField
            source="product"
            reference="payments/product"
            link={false}
          >
            <WithRecord
              render={(product) => {
                console.log('product record', product);
                return (
                  <>
                    <Button onClick={handleButtonClick}> Create Price</Button>
                    <Drawer
                      open={drawerOpen}
                      onClose={handleDialogClose}
                      anchor="right"
                    >
                      <CreatePrice product_id={product.id} />
                    </Drawer>
                  </>
                );
              }}
            />
            {/* <TextField source="name" /> */}
            {/* <TextField source="price" /> */}
            <ReferenceArrayField
              label={<span className="boldLabel"> Features</span>}
              reference="payments/price"
              source="prices"
            >
              <Datagrid>
                <TextField source="type" />
                <TextField source="currency" />
                <TextField source="recurring_interval" />
                <TextField source="unit_amount" />
              </Datagrid>
            </ReferenceArrayField>
          </ReferenceField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
