import {
  Create,
  Show,
  SimpleForm,
  TextField,
  DateInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  useShowContext,
  SimpleShowLayout,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
// import { Grid } from '@mui/material';

export const ShowUser = (props) => {
  const {
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    error, // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onError` side effect.
    isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
    isLoading, // boolean that is true until the record is available for the first time
    record, // record fetched via dataProvider.getOne() based on the id from the location
    refetch, // callback to refetch the record via dataProvider.getOne()
    resource, // the resource name, deduced from the location. e.g. 'posts'
  } = useShowContext();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error!</div>;
  }
  console.log('ShowUser', resource);
  console.log('ShowUser', props);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
      </SimpleShowLayout>
    </Show>
  );
};
