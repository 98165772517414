import * as React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, SelectInput, RadioButtonGroupInput, DateTimeInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import { useState ,useEffect} from 'react';
import { ReferenceInput, AutocompleteInput, BooleanInput ,useEditContext,useEditController} from 'react-admin';

const EditCoupon = ({ ...props }) => {
    
    const { record } = useEditController(props);

    console.log("Edited Row Data:", record);
    const [discountType, setDiscountType] = useState(() => {
        if (record?.amount_off && record.amount_off > 0) {
            console.log("Amount off is greater than 0, setting discount type to 'fixed'");
            return 'fixed';
        } else {
            console.log("Amount off is not greater than 0, defaulting discount type to 'percent_off'");
            return 'percent_off';
        }
    });
    const [selectedDuration, setSelectedDuration] = useState('');
    const [limitDateRange, setLimitDateRange] = useState(record?.redeem_by !== null);
    const [limitRedemptionCount, setLimitRedemptionCount] = useState(record?.times_redeemed >0);
    const [applyToSpecificCoupon, setApplyToSpecificCoupon] = useState(record?.metadata.length > 0 ?true:false);
   console.log("acccpm",applyToSpecificCoupon);
    const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const intialmeta = record?.metadata.length > 0;
    const handleSelectChange = (event) => {
        setSelectedDuration(event.target.value);
    };

    const handleDiscountTypeChange = (event) => {
        setDiscountType(event.target.value);
    };

    const handleLimitDateRangeChange = (event) => {
        setLimitDateRange(event.target.checked);
    };

    const handleLimitRedemptionCountChange = (event) => {
        setLimitRedemptionCount(event.target.checked);
    };

   
    const handleApplyToSpecificCouponChange = (event) => {
        setApplyToSpecificCoupon(event.target.checked );
    };
    
    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
    };
    useEffect(() => {
        // Update limitDateRange state if redeem_by changes
        setLimitDateRange(record?.redeem_by !== null);
    }, [record?.redeem_by]);

    useEffect(() => {
        // Update limitRedemptionCount state if times_redeemed changes
        setLimitRedemptionCount(record?.times_redeemed > 0);
    }, [record?.times_redeemed]);








    const DurationInput = ({ handleSelectChange, selectedDuration }) => {
        // State to track the number of months for "Multiple Months" option
        const [numMonths, setNumMonths] = useState(null);

        return (
            <div>
                {/* Render NumberInput only when "Multiple Months" is selected */}
                {selectedDuration === 'multiple' && (
                    <NumberInput
                        source="duration_in_months"
                        label="Number of Months"
                        onChange={(event, value) => setNumMonths(value)}
                    />
                )}
            </div>
        );
    };

    const optionRenderer = (choice) => {
        return `${choice.name}`;
    };
   
    return (
        <Edit >
          
            <SimpleForm>
                <TextInput source="name" label="Name" />
                <TextInput source="coupon_id" label="ID (Optional)" />
                <RadioButtonGroupInput
                    source={'dummy'}
                    label="Discount Type"
                    choices={[
                        { id: 'percent_off', name: 'Percentage Discount' },
                        { id: 'fixed', name: 'Fixed Amount Discount' },
                    ]}
                    defaultValue={discountType} 
                    onChange={handleDiscountTypeChange}
                />
                {discountType === 'percent_off' && (
                    <div style={{ position: 'relative' }}>
                        <NumberInput source="percent_off" label="Percentage Off" />
                        <span style={{ position: 'absolute', top: '50%', right: '45px', transform: 'translateY(-50%)' }}>%</span>
                    </div>
                )}
                {discountType === 'fixed' && (
                    <div style={{ position: 'relative' }}>
                        <div style={{ display: 'flex' }}>
                            <NumberInput source="amount_off" label="Discount amount" />
                            <SelectInput
                                source="currency"
                                label="Currency"
                                choices={[
                                    { id: 'USD', name: 'USD' },
                                    { id: 'INR', name: 'INR' },
                                ]}
                                onChange={handleCurrencyChange}
                                defaultValue="USD"
                                style={{ marginLeft: '10px', width: '100px' }}
                            />
                        </div>
                        <span style={{ position: 'absolute', top: '50%', right: '45px', transform: 'translateY(-50%)' }}>{selectedCurrency === 'USD' ? '$' : '₹'}</span>
                    </div>
                )}

                <SelectInput
                    source="duration"
                    label="Duration"
                    choices={[
                        { id: 'forever', name: 'Forever' },
                        { id: 'once', name: 'Once' },
                        { id: 'multiple', name: 'Repeating' },
                    ]}
                    onChange={handleSelectChange}
                    // defaultValue="forever"
                />

                <DurationInput handleSelectChange={handleSelectChange} selectedDuration={selectedDuration} />
                <BooleanInput 
          source="f"
           label="Apply to Specific Products" 
          onChange={handleApplyToSpecificCouponChange}
          checked={applyToSpecificCoupon}
          />
          {applyToSpecificCoupon && (
                    <>
                        <ArrayInput source="metadata" label="Metadata" fullWidth>
                            <SimpleFormIterator>
                                <ReferenceInput reference="payments/product">
                                    <AutocompleteInput optionText={optionRenderer} />
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                        </>
                        )}
                        <div>
                            <h4>Redemption Limits</h4>
                            <div>
                                <label>
                                    <input type="checkbox" checked={limitDateRange} onChange={handleLimitDateRangeChange} />
                                    Limit the date range when customers can redeem this coupon
                                </label>
                                {limitDateRange && (
                                    <div>
                                        <DateTimeInput source="redeem_by" label="Redeem By" />
                                    </div>
                                )}
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" checked={limitRedemptionCount} onChange={handleLimitRedemptionCountChange} />
                                    Limit the total number of times this coupon can be redeemed
                                </label>
                                {limitRedemptionCount && (
                                    <div>
                                        {/* Render additional fields for redemption count limit */}
                                        <NumberInput source="times_redeemed" label="" />
                                        <span style={{ position: 'relative', top: '25px', right: '90px', transform: 'translateY(-50%)' }}>time</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <BooleanInput source="valid" label="Valid" />
                    
                
            </SimpleForm>
        </Edit>
    );
};

export default EditCoupon;
