import { Create, SimpleForm, TextInput, Button } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { RichTextInput } from 'ra-input-rich-text';
// import { Grid } from '@mui/material';

export const CreateFeature = () => {
  const navigate = useNavigate();
  return (
    <Create redirect="list">
      <SimpleForm>
        <TextInput label="Feature name" source="name" />
        <TextInput source="description" fullWidth />
      </SimpleForm>
    </Create>
  );
};
