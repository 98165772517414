import {
  TextField,
  Datagrid,
  List,
  ShowButton,
  EditButton,
  DeleteButton,
  SimpleShowLayout,
} from 'react-admin';

export const ListFeature = (props) => {
  return (
    <List {...props} sort={{ field: 'name', order: 'ASC' }}>
      <>
        <Datagrid>
          <TextField source="name" />
          <TextField source="description" fullWidth />
          <ShowButton />
          <EditButton />
        </Datagrid>
      </>
    </List>
  );
};
