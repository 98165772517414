import React, { useState,useEffect } from 'react';
import {
  Card,
  CardContent,
  Button,
  TextField,
  FormControlLabel, 
  Checkbox
} from '@material-ui/core';
import useSignup from '../../../hooks/useSignup';
import './styles.css';  // Import your external CSS file
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const {
    handleSubmit,
    username,
    setUsername,
    usernameError,
    email,
    setEmail,
    password,
    setPassword,
    passwordError,
    isProcessing,
  } = useSignup();
  const [confirmPassword, setConfirmPassword] = useState('');  // New state for confirmation password
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setconfirmShowPassword] = useState(false);
  const navigate = useNavigate();
  const [termsChecked, setTermsChecked] = useState(false);
  const handleEyeClick = () => {
    setShowPassword(!showPassword);
  };
  const handleconfirmEyeClick = () => {
    setconfirmShowPassword(!showconfirmPassword);

  };
  const handleTermsChange = () => {
    setTermsChecked(!termsChecked);
  };
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    
    // Set the inner text of the script to the provided script code
    script.innerText = `
      gtag('event', 'conversion', {'send_to': 'AW-16473361775/1yJQCOT7kpcZEO-ajq89'});
    `;
    
    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up: remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  return (
    <>
      <div className="login-card">
        <div>
          <h1 className="login-title">SIGNUP</h1>
          <h2 className="login-title-h2">Join Clock Session Today</h2>
          
          <form onSubmit={handleSubmit}>
            <label htmlFor="Name" className="input-label">Name</label>
            <TextField
              id="Full Name"
              placeholder='Enter your name here'
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              required
              error={usernameError !== ''}
              helperText={usernameError}
              className="input-field"
              InputProps={{
  disableUnderline: false,
}}

            />

            <label htmlFor="Email" className="input-label">Email</label>
            <TextField
              id="Work Email"
              placeholder='Enter your e-mail here'
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
              className="input-field"
              InputProps={{
  disableUnderline: false,
}}

            />

            <label htmlFor="password" className="input-label">Password</label>
            <div className="password-wrapper">
              <TextField
                id="Password"
                placeholder='Enter your password here'
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                required
                error={passwordError !== ''}
                // helperText={passwordError}
                className="input-field"
                InputProps={{
  disableUnderline: false,
}}

              />
              <img
                src={showPassword ? '/images/Eye.png' : '/images/Eye-blue.png'}
                alt="Visibility"
                className="eye-button"
                onClick={handleEyeClick}
              />
            </div>

            <label htmlFor="Confirm password" className="input-label">Confirm Password</label>
            <div className="password-wrapper">
              <TextField
                id="confirm-Password"
                placeholder='Re-enter your password here'
                type={showconfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                required
                error={passwordError !== ''}
                // helperText={passwordError}
                className="input-field"
                InputProps={{
  disableUnderline: false,
}}

              />
              <img
                src={showconfirmPassword ? '/images/Eye.png' : '/images/Eye-blue.png'}
                alt="Visibility"
                className="eye-button"
                onClick={handleconfirmEyeClick}
              />
              
            </div>

            <div className="remember-me">
              <FormControlLabel
                control={ <Checkbox
                className="remember-me-checkbox"
                checked={termsChecked}
                onChange={handleTermsChange}

              />}
                label="I agree to the Terms of Services and Privacy Policy"
              />
            </div>
            <Button
             disabled={!termsChecked}
              type="submit"
              variant="contained"
              color="primary"
              className="login-button" 
              style={{ color: 'white' }}
            >
              {isProcessing ? 'Processing ...' : 'SIGNUP'}
            </Button>
          </form>
        </div>
        <div className="horizontal-lines"></div>
        <div className="signup-text">
          <p>
            Have An Account?{" "}
            <button className="SignUpButton" onClick={() => navigate("/app/login")}>
              Log In
            </button>
          </p>
        </div>
      </div>
    </>
  );
};

export default SignUp;
